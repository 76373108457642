import * as types from '../actions/types'
import axios from 'axios'

export const fetchAssignedReview = () => {
  return (dispatch, getState) => {
    axios({
      method: 'GET', 
      url: `/api/assigned-reviews`
    }).then((resp) => {
      dispatch({
        type: types.SET_ASSIGNED_REVIEWS, 
        assigned: resp.data.assigned_reviews
      })

      dispatch({
        type: types.SET_ASSIGNED_REVIEWS_FETCH_ERROR, 
        value: 'Something went wrong while fetching assigned reviews.', 
        errorState: false
      })
    }).catch((err) => {
      dispatch({
        type: types.SET_ASSIGNED_REVIEWS_FETCH_ERROR, 
        value: 'Something went wrong while fetching assigned reviews.', 
        errorState: true
      })
    })
  }
}