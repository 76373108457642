import * as types from '../actions/types'

export const assignedReviews = (state = [], action) => {
  switch(action.type) {
    case types.SET_ASSIGNED_REVIEWS:
      return action.assigned
    default:
      return state
  }
}
