import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image, Rect } from 'react-konva';
import { connect, useDispatch, useSelector, 
  ReactReduxContext, Provider } from 'react-redux'
import { rainbow, uuidv4 } from '../utils';
import { translatePoint, loadImage } from './utils';
import { CANVAS_SET_RECTANGLES } from '../actions/types';
import {  } from "react-redux";
import Polygon from './polygon';
import Toolbar from './toolbar';


const colorSteps = 15; // 15 colors evenly spaced


const DrawingCanvas = ({ backgroundImageUrl }) => {
  const dispatch = useDispatch()

  const isMouseActive = useSelector((state) => state.canvasIsMouseActive)
  const imgStageSize = useSelector((state) => state.imgStageSize)
  const position = useSelector((state) => state.canvasPosition)
  const rectangles = useSelector((state) => state.rectangles)
  const bands = useSelector((state) => state.bands.present)
  const scale = useSelector((state) => state.canvasScale)
  const drawingMode = useSelector((state) => state.canvasDrawingMode)
  const polygons = useSelector((state) => state.polygons)
  const isMovingPoint = useSelector((state) => state.polygonIsMovingPoint)

  const imageRef = useRef(null)
  const stageRef = useRef(null)
  const [image, setImage] = useState(null)

  const [, setStateToken] = useState(0)

  // Draw background image to the Konva canvas
  useEffect(() => {
    if(!backgroundImageUrl) return;
    loadImage(backgroundImageUrl, setImage, stageRef)
    setStateToken(Math.random())
  }, [backgroundImageUrl])

  /*
    Behavior when mouse moves: 
    For rectangles: 
      - Prerequisite: mouse needs to be down
      - Rectangle is updated with diff between start position and current cursor position
    
    For polygons (closed line): 
      - Prerequisite: mouse needs to be down
      - Last line coordinates are continously updated with current cursor position
  */
  const handleMouseMove = (e) => {
    const { offsetX, offsetY } = e.evt;
    const translated = translatePoint({ offsetX, offsetY, scale, positionX: position.x, positionY: position.y})

    if (drawingMode === 'rect' && isMouseActive && !isMovingPoint) {
      const lastIndex = rectangles.length - 1;
      if (lastIndex >= 0) {
        const width = translated.x - rectangles[lastIndex].x;
        const height = translated.y - rectangles[lastIndex].y;

        const updatedRectangles = [...rectangles];
        updatedRectangles[lastIndex] = { ...updatedRectangles[lastIndex], width, height };
        
        dispatch({ type: CANVAS_SET_RECTANGLES, rectangles: updatedRectangles })
      }
    }

    if (drawingMode === 'polygon' && isMouseActive && !isMovingPoint) {
      dispatch({ 
        type: 'CANVAS_ADD_POLYGON_POINT', 
        point: translated
      })
    } else if (drawingMode === 'band' && isMouseActive && !isMovingPoint) {
      const lastIndex = bands.length - 1;
      if (lastIndex >= 0) {
  
        const updatedBands = [...bands];
        updatedBands[lastIndex] = {
          ...updatedBands[lastIndex],
          height: translated.y - updatedBands[lastIndex].y,
          y: translated.y > updatedBands[lastIndex].y ? updatedBands[lastIndex].y : translated.y
        };
  
        dispatch({ type: 'CANVAS_SET_BANDS', bands: updatedBands });
      }
    }

  };

  /*
    Behavior when mouse went down: 
    For rectangles: 
      - setMouseActive: true 
      - Initiate rectangle at given location with size 0 
    
    For polygon: 
      - setMouseActive: true 
      - Initiate polygon at given location with size 0 
  */
  const handleMouseDown = (e) => {
    const { offsetX, offsetY } = e.evt;
    const translated = translatePoint({ offsetX, offsetY, scale, positionX: position.x, positionY: position.y});

    dispatch({ type: 'CANVAS_IS_MOUSE_ACTIVE', canvasIsMouseActive: true })

    if (drawingMode === 'rect' && !isMovingPoint) {
      dispatch({
        type: 'CANVAS_SET_RECTANGLES', 
        rectangles: [...rectangles, { 
          x: translated.x, y: translated.y, width: 0, height: 0, 
          color: rainbow(colorSteps, Math.floor(Math.random() * colorSteps)), 
          creator: 'user', 
          conf_score: 1
       }]
      })
    } else if (drawingMode === 'polygon' && !isMovingPoint) {
      dispatch({ 
        type: 'CANVAS_ADD_POLYGON', 
        point: translated
      })
    } else if (drawingMode === 'band' && !isMovingPoint) {
      dispatch({
        type: 'CANVAS_SET_BANDS',
        bands: [
          ...bands,
          {
            x: 0,
            y: translated.y,
            width: imgStageSize.w,
            height: 0,
            color: rainbow(colorSteps, Math.floor(Math.random() * colorSteps)),
            creator: 'user',
            conf_score: 1,
            assigned_by: null, 
            assigned_to: null,
            content_review_status: 'reviewed',
            identifier: uuidv4(), 
            value: ""
          },
        ],
      });
    }
  };

  /* 
    Behavior when mouse went up
    For rectangles: 
      - setMouseActive: false 
    
    For polygons: 
        - setMouseActive continues to be true unless: 
        - polygon is closed
        - ESC button was pressed, causing the line to be removed
  */ 
  const handleMouseUp = (e) => {
    dispatch({ type: 'CANVAS_IS_MOUSE_ACTIVE', canvasIsMouseActive: false })

    if (drawingMode === 'polygon') {
      dispatch({ type: 'CANVAS_DEACTIVATE_POLYGONS' })
      dispatch({ type: 'CANVAS_POLYGON_IS_MOVING_POINT', isMovingPoint: false })
      e.target.getStage().container().style.cursor = 'grab'
    } else if (drawingMode === 'band') {
      const lastIndex = rectangles.length - 1;
      if (lastIndex >= 0) {
        const band = rectangles[lastIndex];

        // Prevent bands with no height
        if (Math.abs(band.height) < 1) {
          const updatedBands = rectangles.slice(0, lastIndex); // Remove the incomplete band
          dispatch({ type: 'CANVAS_SET_BANDS', bands: updatedBands });
        }
      }
    }
  };

  const handleWheel = (e) => {
    e.evt.preventDefault()
    const currentStageRef = stageRef.current;
  
    if (!currentStageRef) return 
    const stage = currentStageRef.getStage();

    if (e.evt.ctrlKey) {
      const pointerPosition = stage.getPointerPosition();
      const { x: pointerX, y: pointerY } = pointerPosition;

      const mousePointTo = {
        x: pointerX / scale - stage.x() / scale,
        y: pointerY / scale - stage.y() / scale,
      };

      let unboundedScale = scale - e.evt.deltaY * 0.01;

      const lowerBound = 0.2;
      if (unboundedScale < lowerBound) {
        unboundedScale = lowerBound + Math.exp((unboundedScale - lowerBound) * 5) - 1; // Exponential dampening
      }
      const newScale = Math.min(Math.max(unboundedScale, lowerBound), 10.0);
      
      const newPosition = {
        x: -(mousePointTo.x - pointerX / newScale) * newScale,
        y: -(mousePointTo.y - pointerY / newScale) * newScale,
      };

      dispatch({ type: 'CANVAS_SET_SCALE', scale: newScale })
      dispatch({ type: 'CANVAS_SET_POSITION', position: newPosition })
    } else {
      const dragDistanceScale = 2
      const newPosition = {
        x: position.x - dragDistanceScale * e.evt.deltaX,
        y: position.y - dragDistanceScale * e.evt.deltaY
      }

      dispatch({ type: 'CANVAS_SET_POSITION', position: newPosition })
    }
  }

  const anchorDragMove = (e, anchorId, polygonId, scale) => {
    const { offsetX, offsetY } = e.evt;
    const anchor = translatePoint({ offsetX, offsetY, scale, positionX: position.x, positionY: position.y})
    const anchorPosition = { x: anchor.x, y: anchor.y } 

    dispatch({ type: 'CANVAS_MOVE_ANCHOR', anchorId: anchorId, position: anchorPosition, polygonId: polygonId })
  }

  return (
      <div>
        <Toolbar />
        {/* 
          Dirty hack as described: https://github.com/konvajs/react-konva/issues/311#issuecomment-454411007
          This is to ensure that the Konva stage is able to access the Redux store

          Todo: update React Konva to >= 18.0.0
        */}
        <ReactReduxContext.Consumer>
          {({ store }) => (
          <Stage
            ref={stageRef}
            width={imgStageSize.w}
            height={imgStageSize.h}
            onMouseDown={(e) => handleMouseDown(e)}
            onMouseMove={(e) => handleMouseMove(e)}
            onMouseUp={(e) => handleMouseUp(e)}
            onMouseEnter={e => {
              const container = e.target.getStage().container();
              container.style.cursor = "crosshair";
            }}
            onMouseLeave={e => {
              const container = e.target.getStage().container();
              container.style.cursor = "default";
            }}
            onWheel={handleWheel}
            scaleX={scale}
            scaleY={scale}
            x={position.x}
            y={position.y}
          >
            <Provider store={store}>
              <Layer>
                {image && 
                  <Image image={image} ref={imageRef} width={imgStageSize.w} height={imgStageSize.h}/>
                }
                {rectangles.map((rect, index) => (
                  <span>
                    <Rect key={index} {...rect} stroke={rect.color} strokeWidth={2}/>
                  </span>
                ))}
                {polygons.map((polygon, index) => (
                  <Polygon key={index} polygon={polygon} scale={scale} anchorDragMove={anchorDragMove} />
                ))}
                {bands.map((band, index) => (
                  <Rect
                    key={`band-${index}`}
                    x={band.x} // Always 0 for bands
                    y={band.y} // Controlled by the user
                    width={band.width} // Equal to imgStageSize.w
                    height={band.height} // Controlled by the user
                    stroke={band.color} // Border color of the band
                    strokeWidth={2} // Border thickness
                    fill={null} // No fill for transparency
                  />
                ))}
              </Layer>
            </Provider>
          </Stage>
          )}
        </ReactReduxContext.Consumer>
      </div>
  );
};

const mapStateToProps = state => {
  return { lines: state.lines };
}

export default connect(mapStateToProps)(DrawingCanvas);
