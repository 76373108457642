import React from 'react';

const AssignedReviewWidgetComponent = ({ assigned, projectId }) => (
  <div className="relative overflow-hidden rounded-lg bg-white shadow">
    <div className="px-4 py-8 sm:px-6 pb-8">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Assigned to you</h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">Images to be reviewed by you.</p>
    </div>

    <div className="border-t border-gray-200"></div>

    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            By
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Image
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {assigned.map((item, index) => 
          <tr key={`assigned-item-${index}`}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
            <div className="inline-block size-16 items-center justify-center rounded-full bg-gray-500 w-8 h-8 text-center">
              <div className="text-xs font-medium text-white pt-2">{item.name.toUpperCase().substring(0,2)}</div>
            </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
              <img src={item.image_url} alt=""/>
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <a href={`${projectId}/text-line-review/${item.notebook_id}/${item.paragraph_id}/`} className="text-indigo-600 hover:text-indigo-900">
                Review
              </a>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
)

export default AssignedReviewWidgetComponent