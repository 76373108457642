export const loginStatus = (state = {}, action) => {
  switch(action.type) {
    case 'USER_LOGGED_IN': 
      const newState =  { email: action.identifier } 
      document.cookie = `accessToken=${action.accessToken}; path=/`
      document.cookie = `refreshToken=${action.refreshToken}; path=/`
      document.cookie = `identifier=${action.identifier}; path=/`

      if(window.location.pathname !== '/login') {
        window.location.reload();
      } else {
        window.location.replace('/')
      }

      return newState
    default: 
      return state
  }
}

export const loginError = (state = false, action) => {
  switch(action.type) {
    case 'USER_LOGIN_ERROR':
      const newState = action.error
      return newState
    default:
      return state
  }
}