import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/index'
import { persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import storage from 'redux-persist/lib/storage' // default: localStorage if web, AsyncStorage if react-native

let middleware = [thunk]; 

if(process.env.NODE_ENV === 'development') {
	middleware = [...middleware, logger]; 
} else {
	middleware = [...middleware]; 
}

const config = {
  key: 'primary', 
  storage
}

const reducer = persistReducer(config, rootReducer)
const store = createStore(reducer, undefined, composeWithDevTools(applyMiddleware(...middleware)));  

export default store