import React, { Component } from 'react';
import AssignedReviewWidgetComponent from './AssignedReviewWidgetComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AssignedReviewWidgetActions from './AssignedReviewWidgetActions'

class AssignedReviewWidget extends Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this.props.actions.fetchAssignedReview()
  }

  render() {
    return (
      <AssignedReviewWidgetComponent assigned={this.props.assigned} projectId={this.props.projectId}/>
    )
  }

}


function mapStateToProps(state) {
  return {
    assigned: state.assignedReviews, 
    projectId: state.currentProjectId, 
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, AssignedReviewWidgetActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedReviewWidget)
